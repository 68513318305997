<template>
	<div>
		<h2>{{ $t('invoice.supplier_duplicate_control') }}</h2>

		<div class="row">
			<div class="col-6">
            	<iframe :src="invoices[0].suppliersocr_pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
			</div>
			<div class="col-6">
            	<iframe :src="invoices[1].suppliersocr_pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
			</div>

			<div class="text-center mt-2 col-12">
                <button @click="keepBoth" class="btn btn-primary rounded-pill mr-4">
                    <font-awesome-icon v-if="processing_keep" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'check']" /> {{ $t('invoice.supplier_keep_both') }}
                </button>
                <button @click="deleteInvoiceOlder" class="btn btn-secondary rounded-pill">
                    <font-awesome-icon v-if="processing_delete" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'trash-alt']" /> {{ $t('invoice.supplier_delete_old') }}
                </button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"

	export default {
		name: 'DuplicateControl',
		mixins: [Invoice, Shutter, ShutterInvoice],
		props: ['invoices'],
		data () {
			return {
				processing_keep: false,
				processing_delete: false
			}
		},

		methods: {
			async keepBoth() {
				this.processing_keep = true
				let invoices_ids = this.invoices.map(invoice => invoice.suppliersocr_id).join(',')
				await this.setDuplicateInvoiceSupplier(invoices_ids)
				this.processing_keep = false
				this.done()
			},

			async deleteInvoiceOlder() {
				this.processing_delete = true
				await this.deleteInvoiceSupplier(this.invoices[1].suppliersocr_id)
				await this.setDuplicateInvoiceSupplier([this.invoices[0].suppliersocr_id])
				this.processing_delete = false
				this.done()
			},

			done() {
				this.ok()
				this.shutterPanel().close('invoice-supplier-duplicate-control')
				this.successToast('toast.info_save_succes')
			}
		},

		components: {
			
		}
	}

</script>

6620d4d41a694_86170.pdf